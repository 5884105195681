import api from '../axios'

export default {
    getInvitations: payload => api.get('api/practice-invitations'),
    getInvitationsCount: payload => api.get('api/practice-invitations/count'),
    showInvitations: token => api.get(`api/practice-invitations/${token}`),
    accept: token => api.post(`api/practice-invitations/${token}/accept`),
    reject: token => api.post(`api/practice-invitations/${token}/decline`),


    // pcn invitations
    pcn_getInvitations: payload => api.get('api/pcn-invitations'),
    pcn_showInvitations: token => api.get(`api/pcn-invitations/${token}`),
    pcn_accept: token => api.post(`api/pcn-invitations/${token}/accept`),
    pcn_reject: token => api.post(`api/pcn-invitations/${token}/decline`),
}
